import { Link } from '@inertiajs/react';
import '../../../public/assets/css/vendor.bundle49f7.css'
import '../../../public/assets/css/style49f7.css'
import '../../../public/home/css/custom.css'
import '../../../public/assets/icons/remix-icon/remixicon.css'

import { Box, HStack, VStack, Stack, Text, Button, ButtonGroup} from '@chakra-ui/react'

export default function Guest({ children }) {
    return (
        <Box className='page-ath' >
            <Box className="page-ath-wrap">
                <Box className="page-ath-content" background={'linear-gradient(13deg, rgba(27,32,0,0.7110819327731092) 7%, rgba(15,33,70,0) 63%)'}>
                    <div className="page-ath-header">
                    <a href="/" className="page-ath-logo"><img src={`${window.location.origin}/images/logo/logo-dark.png`} width={300} srcSet={`${window.location.origin}/images/logo/logo-dark.png 2x`} alt="logo" /></a>
                    </div>
                    <div className="page-ath-form">
                       {children}
                    </div>
                    <div className="page-ath-footer">
                        <ul className="footer-links">
                            <li><a href="#" className='text-white'>Privacy Policy</a></li>
                            <li><a href="#" className='text-white'>Terms</a></li>
                            <li className='text-white'>© 2024 PixelVaults NFT.</li>
                        </ul>
                    </div>
                </Box>
                <div className="page-ath-gfx">
                    <div className="w-100 d-flex justify-content-center">
                        <div className="col-md-8 col-xl-5">
                        </div>
                    </div>
                </div>
            </Box>
        </Box>
    );
}

// #102049